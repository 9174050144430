/* Sidebar Styles */
.sidebar {
  /* margin-top: 110px; */
  height: 100vh; /* Full viewport height */
  width: 250px;
  /* position: fixed; */
  top: 0;
  left: 0;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;
}

.collapsed {
  width: 80px;
}

/* Logo */
.logo {
  text-align: center;
  /* padding: 10px; */
  background-color: black;
  font-size: 1.5em;
  cursor: pointer;
  letter-spacing: 1px;
}

/* Make navLinks scrollable */
.navLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1; /* Allow it to take remaining space */
  overflow-y: auto; /* Make this section scrollable */
}

.navLinks li {
  width: 100%;
  text-align: left;
}

.navLinks li a {
  display: block;
  padding: 15px 20px;
  color: black;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s;
}

.navLinks li a:hover {
  background-color: #8badb7;
}

/* Keep logout button fixed at the bottom */
.logout button {
  /* padding: 15px; */
  padding: 15px 92px;
  text-align: center;
  background-color: #405f74;
  position: relative;
}

.logout:hover {
  background-color:#8badb7;
}



.logout button {
  color: white;
  background-color: #405f74;
  font-size: 14px;
  text-decoration: none;
}

.logout button:hover {
  color: #e84118;
  background-color: #8badb7;
}

/* Collapsed styles */
.collapsed .navLinks li a {
  text-align: center;
  font-size: 0;
}

.collapsed .navLinks li a i {
  font-size: 1.3em;
}

.collapsed .logout a {
  font-size: 0;
}

.collapsed .logout a i {
  font-size: 1.3em;
}

/* Responsive */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .collapsed {
    width: 60px;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 100px;
  }

  .collapsed {
    width: 50px;
  }
}

/* Scrollbar styling (optional) */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #718093;
  border-radius: 10px;
}
