.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darker backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out; /* Add fade-in effect */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .modal {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 15px #405f74;
    position: relative;
    animation: slideIn 0.3s ease; /* Add slide-in animation */
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #ddd; */
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .header h2 {
    /* text-align: center; */
    margin: 0;
    font-size: 16px;
    /* font-weight: bold; */
    color: #333;
  }
  
  .closeIcon {
    font-size: 1.5rem;
    color: #555;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .closeIcon:hover {
    color: #d9534f; /* Close icon changes to red on hover */
  }
  
  .content {
    font-size: 14px;
    color: #555;
  }
  