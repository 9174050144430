.bannerContainer {
    position: relative;
    width: 100%;
    height: 350px;
    max-width: 1263px; /* Optional */
    margin: 7.3rem auto;
    margin-bottom: 70px;
  }
  
  .bannerImage {
    width: 100%;
    height: 120%;
    display: block;
   
  }
  
  .bannerButton {
    position: absolute;
    bottom: -30px; 
    right: 40px; 
    padding: 10px 20px;
    font-size: 14px;
    background-color: #1F3340; 
    color: #fff;
    border: none;
    /* border-radius: 5px; */
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
  }
  
  .bannerButton:hover {
    color: black;
    background-color: white; /* Hover state */
 
  }
  
  /* For screens larger than 1400px */
  @media (min-width: 1400px) {
    .bannerButton {
      font-size: 18px; /* Slightly larger button text */
      padding: 14px 28px; /* Increased padding for bigger screens */
      bottom: 25px; /* Adjusted position */
      right: 25px; /* Adjusted position */
    }
  }
  
  /* For screens larger than 1600px */
  @media (min-width: 1600px) {
    .bannerButton {
      font-size: 20px; /* Larger button text for better visibility */
      padding: 16px 32px; /* Increased padding */
      bottom: 30px; /* Adjusted position */
      right: 30px; /* Adjusted position */
    }
  }
  
  /* For screens smaller than 768px */
  @media (max-width: 768px) {
    .bannerButton {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  
  /* For screens smaller than 480px */
  @media (max-width: 480px) {
    .bannerButton {
      font-size: 12px;
      padding: 8px 16px;
      bottom: 10px;
      right: 10px;
    }
  }
  