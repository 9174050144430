.pageLayout {
    display: flex;

  }
  
  .sidebar {
    width: 250px;
    background-color: white;
  } 
.container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
    /* max-width: 600px;
    max-height: 330px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px #405f74;
    flex-grow: 1;
    padding: 10px; */
  
  }
  
  h2 {
    /* text-align: left; */
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
   
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  label {
    font-size: 14px;
    color: #555;
    font-family: "EnnVisions";
  }
  
  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .addButton {
    padding: 10px 20px;
    background-color: #405f74;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    font-family: "EnnVisions";
  }
  
  .addButton:hover {
    background-color: #8badb7;
  }

  .header{
    display: flex;
    justify-content: space-between;
  }
  .header h2{
    margin-top: 15px;

  }

  .categorylist{
    /* padding: 8px 12px;
    margin-top: 10px; */
    padding: 0px 15px 0px 15px;
    margin-top: 15px;
    background-color: #405f74;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    font-family: "EnnVisions";
  }
 
  .categorylist:hover {
    background-color: #8badb7;
  }
  