.pagecontainer {
    width: 100%;
    margin-top: 7.3rem;
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 20px; */
  }
  
  /* Full-Width Image Section */
  .image_section img {
    margin-top: 7.3rem;
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
    /* margin-bottom: 20px; */
  }
  
  /* Text and Image Section */
  .text-image-section {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    /* margin-top: 10px; */
    gap: 20px;
  }
  .text-text-section {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    margin-top: 10px;
    gap: 20px;
  }
  
  .text-content {
    flex: 1;
    min-width: 280px;
    margin: 5px;
    text-align: justify;
    text-justify: inter-word;
  }
  .text-content2 {
    flex: 1;
    min-width: 280px;
    margin: 15px;
    text-align: justify;
    text-justify: inter-word;
  }
  
  .text-content h3 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .text-content2 h3 {
    margin-bottom: 35px;
    margin-top: 35px;
  }
  
  .text-content h5 {
    margin-bottom: 15px;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    /* margin-top: 15px; */
    margin-top: 70px;
  }
  .text-content1 h5 {
    margin-bottom: 15px;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    margin-top: 60px;
  }
  
  .text-content p {
    color: #555;
    line-height: 1.6;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
    /* font-size: 0.95rem; */
  }
  .text-content2 p {
    color: #555;
    line-height: 1.6;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 25px;
    /* font-size: 0.95rem; */
  }

  .text-content span {
    font-weight: 600;
  }
  
  .image-content {
    flex: 1;
    min-width: 280px;
  }
  
  .image-content img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  .teenagers_div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #466CAB;
    color: white;
    padding: 12px;
    font-size: 20px;
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .text-image-section {
      flex-direction: column;
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    .text-text-section {
      flex-direction: column;
      text-align: center;
    }
  
    .image-content img {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  .responsive-text-section1{
display: flex;
  }


  .responsive-text-section {
    text-align: justify;
    text-justify: inter-word;
    margin-left: 3px;
  }
  
  .responsive-text-section h5 {
    /* font-size: 1.1rem; */
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 8px;
  }
  
  .responsive-text-section p {
    /* font-size: 0.95rem; */
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  