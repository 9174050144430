input{
  font-size: 12px;
}

.ql-editor {
  font-family: 'EnnVisions', sans-serif !important;
}

.pageLayout {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #f5f5f5;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
}

.form {
  display: flex;
  justify-content: space-between; /* Keep the preview on the right */
}

.formFields {
  flex-grow: 1;
}

.formGroup {
  margin-bottom: 15px;

}

label {
  margin-bottom: 5px;
  font-size: 14px;
}

input,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
 
}

.error {
  color: red;
  font-size: 0.875rem;
}

.submitButton {
  background-color: #405f74;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #8badb7;
}

/* Common preview styling */
.preview {
  margin-left: 10px;
  flex-shrink: 0;
  flex-direction: row;
  gap: 10px;
}

.previews {
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.preview p {
font-size: 14px;
}

.preview iframe,
.preview img {
  width: 240px;
  height: 135px;
  border: 1px solid #ccc;
  border-radius: 4px;
  object-fit: cover;
}

/* Specific styles for category and subcategory inputs */


.categorySubcategory select {
  flex: 2; /* Allow select elements to grow and fill the remaining space */
}

/* Thumbnail input styling */
.thumbnailInput {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .content {
    padding: 10px;
  }

  .form {
    flex-direction: column;
  }

  .preview {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .categorySubcategory {
    flex-direction: column;
  }
}

.categorySubcategorydiv{
display: flex;
flex-direction: column;

}


.categorySubcategory {
  display: flex;
  align-items: flex-start; 
  flex-wrap: wrap; 
  flex-direction: column;
}

.category {
  flex: 1; /* Allows inputs to grow and share space evenly */
  min-width: 200px; /* Ensures inputs do not become too narrow */
}


select,
.reactSelectDropdown .selectSubCat {
  width: 100%;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 4px;
  background-color:white; /* Light background color */
  transition: border-color 0.3s, box-shadow 0.3s;
}

select:focus,
.reactSelectDropdown .selectSubCat:focus {
  border-color:  #405f74; /* Blue border on focus */
  /* box-shadow: 0 0 5px #405f74; */
}

.reactSelectDropdown .selectSubCat {
  min-height: 50px;
}

option {
  background-color: white; /* White background for options */
  color: #333; /* Dark gray text */
}

.reactSelectDropdown .selectSubCat__control {

  box-shadow: none;
}

.reactSelectDropdown .selectSubCat__control--is-focused {
  border-color: #405f74;
}

.reactSelectDropdown .selectSubCat__menu {
  z-index: 1000;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  
}

.reactSelectDropdown .selectSubCat__option--is-focused {
  background-color: #f1f8ff; /* Light blue background on hover */
  color: #8badb7; /* Blue text */
}

.reactSelectDropdown .selectSubCat__option--is-selected {
  background-color: #8badb7; /* Blue background for selected option */
  color: #fff; /* White text for selected option */
}

.error {
  color: #d9534f; /* Red for error messages */
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

