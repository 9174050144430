.pageLayout {
  display: flex;
}
.ql-editor {
  font-family: 'EnnVisions', sans-serif !important;
}
.sidebar {
  width: 250px;
  background-color: #f5f5f5;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 16px;
}

.form {
  display: flex;
  justify-content: space-between; /* Keep the preview on the right */
}

.formFields {
  flex-grow: 1;
}

.formGroup {
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-size: 14px;
}

input,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 0.875rem;
}

.submitButton {
  background-color: #405f74;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #8badb7;
}

/* Common preview styling */
.preview {
  margin-left: 10px;
  flex-shrink: 0;
  flex-direction: row;
  gap: 10px;
}

.previews {
  margin: 5px;
  display: flex;
  flex-direction: column;
}
.preview p {
font-size: 14px;
}

.preview iframe,
.preview img {
  width: 240px;
  height: 135px;
  border: 1px solid #ccc;
  border-radius: 4px;
  object-fit: cover;
}

/* Specific styles for category and subcategory inputs */
.categorySubcategory {
  display: flex; /* Enable flexbox for this group */
  gap: 1rem; /* Space between inputs */
}

.categorySubcategory select {
  flex: 1; /* Allow select elements to grow and fill the remaining space */
}

/* Thumbnail input styling */
.thumbnailInput {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .content {
    padding: 10px;
  }

  .form {
    flex-direction: column;
  }

  .preview {
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .categorySubcategory {
    flex-direction: column;
  }
}
