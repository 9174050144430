/* Add this to your CSS file or a styled component */
.pageLayout {
    display: flex;
    /* flex-direction: column; */
    min-height: 100vh; /* Make sure it takes full height */
  }
  
  .content {
    flex: 1; /* This will push the pagination to the bottom */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures pagination sticks to the bottom */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto; /* Ensures it sticks to the bottom of the container */
    padding: 10px;
    gap: 15px; /* Space between buttons */
    font-size: 12px;
  }
  
  .pagination button {
    background-color: #405f74; /* Button color */
    color: #fff;
    border: none;
    border-radius: 20px; /* Makes buttons round */
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:disabled {
    background-color: #ccc; /* Lighter color for disabled buttons */
    cursor: not-allowed;
    font-size: 12px;
  }
  
  .pagination button:not(:disabled):hover {
    background-color: #8badb7; /* Darker shade on hover */
  }
  
  
  
  
  .pageLayout {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    background-color: white;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: -20px;
  
  }
  
  .filterBox select {
    margin-left: 7px;
    padding: 5px;
    margin-right: 35px;
  }
  
  /* .filterBox option select:hover{
    background-color: #8badb7;
  } */
  
  .uploadButton {
    background-color: #405f74;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .videoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .videoCard {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .videoPreview {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0px 0px;
  }

  .videoInfo h6{
    cursor: pointer;
  }
  
  .videoInfo {
    padding: 15px;
  }
  
  .videoInfo p {
    font-size: 12px;
    font-family: "EnnVisions";
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    /* position: fixed; */
   
  }
  
  .actions div {
    cursor: pointer;
    color:  #050e10;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "EnnVisions";
  }
  
  .actions svg {
    margin-right: 5px;
  }
  
  .uploadButton:hover {
    background-color: #8badb7;
  }
  
  .deleteButtons {
    background-color: #405f74 ;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .deleteButtons:hover {
    background-color: #8badb7;
  }
  
  .filterBox{
    display: flex;
  
  }
  
  .category {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px ;
    width: 100%;
    max-width: 400px;
  }
  
  .dropdownContainer {
    width: 100%;
  }
  
  .dropdown {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333;
    outline: none;
    cursor: pointer;
  }
  
  .dropdown:focus {
    border-color:  #8badb7;
    box-shadow: 0px 0px 6px  #8badb7;
  }
  
  @media (max-width: 768px) {
    .category {
      gap: 8px;
    }
  
    .dropdown {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
  
  @media (max-width: 480px) {
    .dropdown {
      font-size: 0.8rem;
      padding: 6px;
    }
  }
  
  /* General Responsiveness for Larger Screens */
  @media (min-width: 1440px) {
    .pageLayout {
      padding: 20px;
    }
  
    .sidebar {
      width: 300px; /* Slightly larger sidebar for bigger screens */
    }
  
    .content {
      padding: 40px; /* More padding for spacious feel */
    }
  
    .filterBox select {
      padding: 10px; /* Increase padding for select boxes */
      margin-right: 50px;
      font-size: 1.1rem; /* Slightly larger font for readability */
    }
  
    .uploadButton {
      font-size: 1.1rem; /* Larger font size */
      padding: 15px 30px; /* More spacious button */
    }
  
    .videoGrid {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Larger cards */
      gap: 30px; /* Increased gap between cards */
    }
  
    .videoCard {
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow */
    }
  
    .videoInfo p {
      font-size: 14px; /* Adjust text size for better visibility */
    }
  
    .actions div {
      font-size: 14px; /* Larger action text */
    }
  }
  
  @media (min-width: 1600px) {
    .sidebar {
      width: 350px; /* Further increased sidebar width */
    }
  
    .content {
      padding: 60px; /* Extra padding for very large screens */
    }
  
    .filterBox select {
      font-size: 1.2rem; /* Larger font size */
      margin-right: 60px;
    }
  
    .uploadButton {
      font-size: 14px; /* Adjust button text size */
      padding: 20px 40px;
    }
  
    .videoGrid {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Larger grid items */
      gap: 40px; /* Extra space between grid items */
    }
  
    .videoCard {
      padding: 20px; /* Add padding for extra spacing */
    }
  
    .videoInfo p {
      font-size: 14px; /* Text size for 1600px screens */
    }
  }
  