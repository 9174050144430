.contact-card {
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .contact-form h5{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  
  .form-label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  
  .required {
    color: red;
  }
  
  .form-input,
  .form-select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .phone-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .alert-container {
    font-size: 12px;
    color: #555;
    margin-top: 10px;
  }
  
  .kids-submit-button {
    background-color: #72BECB;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* .submit-button:hover {
    background-color:  #f6e9e1b0;
  } */
  