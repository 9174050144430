
.pageLayout {
  display: flex;
  min-height: 100vh;
  background-color: #fafafa;
}

.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
}

.mainContent {
  flex: 1;
  padding: 20px;
}

.mainContentWrap{
width: 100vw;
  padding: 30px;
}

.leadsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.card {
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #ececec, #ffffff);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.card h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #333;
}

/* Media Queries */
@media (max-width: 1600px) {
  .card {
    width: 140px;
    height: 140px;
    font-size: 16px;
  }

  .mainContent {
    padding: 40px;
  }
}

@media (max-width: 1400px) {
  .card {
    width: 90px;
    height: 90px;
    font-size: 14px;
  }

  .mainContent {
    padding: 10px 0px 0px 0px;
    ;
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .mainContent {
    padding: 10px;
  }

  .card {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 80px;
    height: 80px;
    font-size: 12px;
  }
}
.notificationBadge {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}


