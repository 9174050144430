.pageContainer {
  display: flex;
  margin-top: 7.3rem; 
}

.sidebar {
  width: 200px;
  background-color: white;
  padding: 15px;
  position: sticky;
  top: 5.3rem; /* Adjust based on header height */
  max-height: calc(100vh - 7.3rem); /* Prevent overlapping the footer */
  overflow-y: auto; /* Scrollable sidebar */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
}




.videoContent {
  flex-grow: 1;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
  gap: 20px; 
  justify-items: center;
}


.iframe {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
  
}

.videoPreview {
  width: 100%; /* Matches the card's width */
  height: 200px; /* Matches the iframe height */
  overflow: hidden; /* Ensures uniform appearance */
}


.actions {
  display: flex;
  justify-content: end;
  margin-left: 5px;
  position: relative;
  right: 15px;
  /* position: fixed; */
 
}

.actions p {
  cursor: pointer;
  color: #405f74;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: "EnnVisions";
  margin-top: 5px;
  margin-left: 2px;
}


.categoryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.categoryItem {
  font-size: 14px;
  padding: 8px 10px;
  /* background-color: #8badb7; */
  background-color: white;
  color: black;
  /* border-radius: 5px; */
  cursor: pointer;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;
}

.categoryItem:hover,
.activeCategory {
  background-color: #8badb7;
}

.subcategoryList {
  list-style-type: none;
  padding-left: 15px; /* Indent subcategories */
  margin: 5px 0;
}

.subcategoryItem {
  font-size: 12px;
  padding: 5px 10px;
  background-color: white;
  color: black;
  /* border-radius: 3px; */
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.subcategoryItem:hover {
  background-color: #8badb7;
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }
}

@media (max-width: 480px) {
  .pageContainer {
    flex-direction: column;
  }

  .sidebar {
    position: static;
    width: 100%;
    margin-bottom: 20px;
  }

  .videoContent {
    margin-left: 0;
  }
}
