/* Modal container */
.modal {

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 640px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* Close button (X) */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
}
