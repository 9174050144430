.carousel-container {
    display: flex;
    gap: 22px; /* This will add spacing between the cards */
   
    overflow: hidden; /* Ensure the extra space doesn't affect layout */
  }
  
  .videoCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 268px; /* Card width */
    flex-wrap: wrap;
  }
  
  .videoPreview iframe {
    width: 100%;
    height: 150px; /* Set a consistent height for video preview */
    border-radius: 8px 8px 0px 0px;
  }
  
  .videoInfo {
    margin-top: 10px;
  }
  
  .videoInfo h6 {
    font-size: 14px;
    margin:0px 0px 9px -20px;
    color:  #333333;


  }
  
  .videoInfo p {
    font-size: 12px;
    color:  #333333;
    margin-bottom: 21px;
    margin-left: -20px;
    letter-spacing: 0.5px;

  }
  
  