.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f7f6;
}

.formWrapper {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px #405f74;
  width: 100%;
  max-width: 400px;
}

.title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.inputGroup {
  margin-bottom: 1rem;
  font-family: "EnnVisions";
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: black;
}

.input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #8badb7;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease;
}

.input:focus {
  border-color: #405f74;
}

.button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #405f74;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-family: "EnnVisions";
}

.button:hover {
  background-color: #8badb7;
}

.error {
  margin-top: 0.25rem;
  color: red;
  font-size: 0.85rem;
  text-align: left;
  font-family: "EnnVisions";
}

.errorMessage {
  color: red;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "EnnVisions";
}

.successMessage {
  text-align: center;
  font-size: 0.9rem;
  color: green;
  font-family: "EnnVisions";
}

.backToLogin {
  text-align: center;
  margin-top: 1rem;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
