.dropdownContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: 100%;
    max-width: 400px;
  }
  
  .label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
  }
  
  .dropdown {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333;
    outline: none;
    cursor: pointer;
  }
  
  .dropdown:focus {
    border-color: #007bff;
    box-shadow: 0px 0px 6px rgba(0, 123, 255, 0.5);
  }
  
  .dropdownContainer {
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    .dropdownContainer {
      padding: 5px;
    }
  
    .dropdown {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .dropdown {
      font-size: 0.8rem;
      padding: 8px;
    }
  
    .label {
      font-size: 0.9rem;
    }
  }
  