.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    max-width: 1263px; /* Optional */
    margin-bottom: 0px;

  }
  
  .header {
    font-size: 24px;
    padding: 8px;
    /* font-weight: bold; */
    margin-bottom: 0px;
    color: white;
    background-color: #3E3E3E;
  }
  
  .imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
  }
  
  .imageWrapper {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .imageWrapper:hover {
    /* transform: scale(1.05); */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 8px 0px;
    object-fit: cover;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .header {
      font-size: 18px;
    }
  
    .imageGrid {
      gap: 10px;
    }
  }
  