.pageLayout {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    background-color: #f5f5f5;
  } 


.container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  h2 {
    font-size: 16px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .table {
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .table th, .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    font-family: "EnnVisions";
  }
  
  .table th {
    background-color: #f4f4f4;
    color: #333;
    font-size: 14px;
    font-weight:500;
  }
  
  .noData {
    text-align: center;
    padding: 20px;
    color: #999;
  }
  
  .deleteButton {
    padding: 6px 10px;
    font-size: 14px;
    color: #fff;
    background-color: #405f74 ;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px;
  }
  
  .deleteButton:hover {
    background-color: red;
  }
  
  @media (max-width: 768px) {
    .table th, .table td {
      padding: 10px;
    }
  
    h2 {
      font-size: 16px;
    }
  }
  
  .editButton {
    padding: 6px 10px;
    font-size: 14px;
    color: #fff;
    background-color: #405f74;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .editButton:hover {
    background-color:#8badb7;
  }
  
  .saveButton, .cancelButton {
    padding: 6px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
  }
  
  .saveButton {
    color: #fff;
    background-color: #405f74;
  }

  .saveButton:hover {
    background-color:#8badb7;
  }
  
  .cancelButton {
    color: #fff;
    background-color: #405f74 ;
  }
  
  .cancelButton:hover {
    background-color: red;
  }